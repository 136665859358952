import React from "react";
import Container from "components/common/Container";
import ContactForm from "./ContactForm";
import { Wrapper, Details, Address } from "./styles";

export default () => (
  <Wrapper as={Container} id="contact">
    <Details>
      <ContactForm />
    </Details>
    <Address>
      <h2>Contact information</h2>

      <h4>CloudBuilder BVBA</h4>

      <p>
        Groot-Bijgaardenstraat 14
        <br/>
        1082 Sint-Agatha-Berchem
        <br/>
        Belgium
      </p>
    </Address>
  </Wrapper>
);
